Array.prototype.append = (el) ->
  this.push el if this.push?
  this

myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 134
)
headroom.init()

jQuery.fn.shake = (interval, distance, times) ->
   interval = 60
   distance = 5
   times = 5
   jTarget = $(this)
   jTarget.css('position', 'relative')

   for i in [0..times]
      jTarget.animate
        left: if i%2==0 then distance else distance*-1
      , interval
   
   return jTarget.animate left: 0, interval
   
document.addEventListener 'DOMContentLoaded', () ->
  
  if $('.slider-events').length > 0

    $('.slider-events').slick
      infinite: true
      
      dots: false
      dotsClass: 'slider-dots'
      customPaging: (slider, i) -> 
        return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
        return "<a class='slider-dots-item'></a>"

      arrows: true
      slidesToShow: 5
      slidesToScroll: 1
      swipeToSlide: true
      prevArrow: '<div class="ic2 ic2-sm ic2-control prev"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#chevron-left"/></svg></div>'
      nextArrow: '<div class="ic2 ic2-sm ic2-control next"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#chevron-right"/></svg></div>'
      speed: 500
      autoplay: false
      autoplaySpeed: 5000
      useCSS: false
      cssEase: 'linear'
      focusOnSelect: true
      variableWidth: false

      responsive: [
        {
          breakpoint: 1400
          settings: 
            slidesToShow: 4
            slidesToScroll: 1
            initialSlide: 1
            variableWidth: false
            arrows: false
            dots: true
        }
        {
          breakpoint: 992
          settings: 
            slidesToShow: 3
            slidesToScroll: 1
            initialSlide: 1
            variableWidth: false
            arrows: false
            dots: true
        }
        {
          breakpoint: 768
          settings: 
            slidesToShow: 2
            slidesToScroll: 1
            initialSlide: 1
            variableWidth: false
            arrows: false
            dots: true
        }
        {
          breakpoint: 576
          settings: 
            slidesToShow: 1
            slidesToScroll: 1
            centerMode: true
            centerPadding: '32px'
            initialSlide: 1
            variableWidth: true
            arrows: false
            dots: true
        }
      ]
      