var headroom, myElement;

Array.prototype.append = function(el) {
  if (this.push != null) {
    this.push(el);
  }
  return this;
};

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 134
});

headroom.init();

jQuery.fn.shake = function(interval, distance, times) {
  var i, j, jTarget, ref;
  interval = 60;
  distance = 5;
  times = 5;
  jTarget = $(this);
  jTarget.css('position', 'relative');
  for (i = j = 0, ref = times; (0 <= ref ? j <= ref : j >= ref); i = 0 <= ref ? ++j : --j) {
    jTarget.animate({
      left: i % 2 === 0 ? distance : distance * -1
    }, interval);
  }
  return jTarget.animate({
    left: 0
  }, interval);
};

document.addEventListener('DOMContentLoaded', function() {
  if ($('.slider-events').length > 0) {
    return $('.slider-events').slick({
      infinite: true,
      dots: false,
      dotsClass: 'slider-dots',
      customPaging: function(slider, i) {
        if (slider.slideCount === 1 || slider.slideCount === slider.options.slidesToShow) {
          return false;
        }
        return "<a class='slider-dots-item'></a>";
      },
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      prevArrow: '<div class="ic2 ic2-sm ic2-control prev"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#chevron-left"/></svg></div>',
      nextArrow: '<div class="ic2 ic2-sm ic2-control next"><svg class="fi fi-fill"><use xlink:href="assets/img/icons/grow-sprite.svg#chevron-right"/></svg></div>',
      speed: 500,
      autoplay: false,
      autoplaySpeed: 5000,
      useCSS: false,
      cssEase: 'linear',
      focusOnSelect: true,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 1,
            variableWidth: false,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
            variableWidth: false,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            variableWidth: false,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '32px',
            initialSlide: 1,
            variableWidth: true,
            arrows: false,
            dots: true
          }
        }
      ]
    });
  }
});
